import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import LinksPage from './pages/LinksPage';
import HomePage from './pages/HomePage';

import './App.css';
import EditLink from './pages/admin/EditLinkPage';

function App() {
  return (
    <BrowserRouter>
      <div className="container">
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/links" element={<LinksPage />} />
          <Route path="/admin/edit-link/:linkId" element={<EditLink />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App;