import React from "react";
import { useState, useEffect } from 'react';
import { getLinkGroups } from "../api/kpi";
import LinkList from "../components/LinkList";

function LinksPage() {
    const [ linkGroups, setLinkGroups ] = useState([]);
  
    useEffect(() => {
      getLinkGroups()
      .then(response => setLinkGroups(response.data))
      .catch(error => console.log(error))
    }, []);
  
    return (
        <div className="row">
            {linkGroups.map((linkGroup) => (
                <div key={"groupId-" + linkGroup.linkGroupId} className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
                    {<LinkList linkGroupId={linkGroup.linkGroupId} name={linkGroup.name} links={linkGroup.links} />}
                </div>
            ))}
        </div>
    )
}

export default LinksPage;