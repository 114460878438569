import React from "react";

function LinkGroupSelectList(linkGroups, selectedLinkGroupId) {
    return (
        <>
            <select className="form-control" value={selectedLinkGroupId}>
                {linkGroups.linkGroups.map((g) => (<option value={g.linkGroupId}>{g.name}</option>))}
            </select>
        </>
    )
}

export default LinkGroupSelectList;