import React from "react";
import LinkListItem from "./LinkListItem.js";

function LinkList(props) {
    return (
        <ul key={"list-" + props.linkGroupId} className="list-group">
            <li key={"groupHeader-" + props.linkGroupId} className="list-group-item list-group-item-primary list-group-item-header">{props.name}</li>
            {props.links.map((p) => (<LinkListItem linkId={p.linkId} text={p.text} url={p.url} imageSource={p.imageSource} alt={p.alt} />))}
        </ul>
    )
}

export default LinkList;