import React from "react";
import LinkEditor from "../../components/LinkEditor";
import { useParams } from "react-router-dom";


function EditLink() {
    const linkId = useParams();
    console.log("linkId=", linkId);
    return <LinkEditor linkId={linkId}/>
}

export default EditLink;