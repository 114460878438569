import React from "react";

function Header() {
    return (
            <header>
                <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
                    <div className="container">
                        <img src="/images/BarTender.jpg" className="navbar-logo" alt="Big K" />
                        <a className="navbar-brand" href="/">Big K's Pub</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link text-dark" title="Manage" href="/Identity/Account/Manage">Profile</a>
                                </li>
                                <li className="nav-item">
                                    <form className="form-inline" action="/Identity/Account/Logout?returnUrl=%2F" method="post">
                                        <button type="submit" className="nav-link btn btn-link text-dark">Logout</button>
                                    </form>
                                </li>
                            </ul>
                            <ul className="navbar-nav flex-grow-1">
                                    <li className="nav-item"><a className="nav-link text-dark" href="/Links">Home</a></li>
                                    <li className="nav-item"><a className="nav-link text-dark" href="/Calendar">Calendar</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
	        </header>
    )
}

export default Header;