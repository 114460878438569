import React from "react";
import {useState, useEffect } from "react";
import LinkGroupSelectList from "./LinkGroupSelectList";
import SubmitButton from "./SubmitButton";
import CancelButton from "./CancelButton";
import { getLink } from "../api/kpi";
import { getGroupsForList } from "../api/kpi";


function LinkEditor(props) {
    const linkId = props.linkId.linkId;

    const [linkToEdit, setLinkToEdit] = useState({});
    const [linkGroups, setLinkGroups] = useState([])

    useEffect(() => {
        getLink(linkId)
        .then (response => setLinkToEdit(response.data))
        .catch (error => console.log(error))
      }, [linkId]);

    useEffect(() => {
        getGroupsForList()
        .then (response => setLinkGroups(response.data))
        .catch (error => console.log(error))
    })

    return (
            <form>
                <div className="form-group">
                    <label htmlFor="link-id">Link Id</label>
                    <input className="form-control" type="text" name="link-id" id="link_id" disabled value={linkToEdit.linkId} />
                </div>
                <div className="form-group">
                    <label htmlFor="link-id">Link Group</label>
                    <LinkGroupSelectList linkGroups={linkGroups} selectedLinkGroupId={linkToEdit.linkGroupId} />
                </div>
                <div className="form-group">
                    <label htmlFor="link-text">Text</label>
                    <input className="form-control" type="text" name="link-text" id="link-text" value={linkToEdit.text} />
                </div>
                <div className="form-group">
                    <label htmlFor="display-index">DisplayIndex</label>
                    <input className="form-control" type="number" name="display-index" id="display-index" value={linkToEdit.displayIndex} />
                </div>
                <div className="form-group">
                    <SubmitButton /> <CancelButton />
                </div>
       </form>
           
    )
}

export default LinkEditor;