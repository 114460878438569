import axios from 'axios';

const getOptions = () => {
    return {
      mode: "cors",
      headers: {
        //Authorization: `Bearer ${returnService.getAccessToken()}`,
        "Content-Type": `application/json`,
        Pragma: "no-cache"
      }
    }
  };

  const BASE_URL = "https://api.bigkspub.com";

  const axiosInstance = axios.create({
    baseURL: BASE_URL
  });

export function getLinkGroups() {
      return axiosInstance.get("/linkgroups/get-all", getOptions())
}

export function getLink(linkId) {
  console.log(linkId);
  return axiosInstance.get("/links/get/" + linkId, getOptions())
}

export function getGroupsForList() {
  return axiosInstance.get("/linkgroups/get-groups", getOptions())
}

