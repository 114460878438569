import React from "react";
import ImageTag from "./ImageTag";

function LinkListItem(props) {
    const key = "key=" + props.linkId;

    console.log("key:", key);
    return (
        <li key={props.linkId} className="list-group-item">
            <a key={"link-" + props.linkId} href={props.url}>{props.imageSource ? <ImageTag src={props.imageSource} alt={props.alt} /> : props.text}</a>
        </li>
    )
}

export default LinkListItem;